import React, { useState, useEffect, useMemo } from 'react';
import { 
  Box, Button, Input, Table, TableHead, TableRow, TableCell, TableBody, 
  CircularProgress, Alert, Snackbar, Modal, Typography, Select, MenuItem, 
  List, ListItem, ListItemText, Paper, Stepper, Step, StepLabel, IconButton,
  Tooltip, Chip, TextField
} from '@mui/material';
import { 
  FiCheckCircle, FiAlertCircle, FiMessageCircle, FiUpload, FiEdit, FiInfo,
  FiDollarSign
} from 'react-icons/fi';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import Papa from 'papaparse';

const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
    secondary: { main: '#dc004e' },
    success: { main: '#4caf50' },
    warning: { main: '#ff9800' },
    error: { main: '#f44336' },
  },
});

const steps = ['Dateien hochladen', 'Transaktionen prüfen', 'An Steuerberater senden'];

const DOCSENSETaxBank = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [bankFile, setBankFile] = useState(null);
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ocrResults, setOcrResults] = useState([]);
  const [processingError, setProcessingError] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [ocrFeedback, setOcrFeedback] = useState([]);
  const [warningOpen, setWarningOpen] = useState(false);
  const [bankFileUploaded, setBankFileUploaded] = useState(false);
  const [receiptsUploaded, setReceiptsUploaded] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showUploadReceiptsPrompt, setShowUploadReceiptsPrompt] = useState(false);

  useEffect(() => {
    if (bankFileUploaded && receiptsUploaded) {
      setUploadSuccess(true);
      setTimeout(() => {
        processBankFile();
      }, 2000);
    }
  }, [bankFileUploaded, receiptsUploaded]);


  useEffect(() => {
    if (transactions.length > 0 && ocrResults.length > 0 && transactions.some(t => t.status === 'unmatched')) {
      console.log('Matching transactions...'); 
      console.log('Transactions:', transactions); 
      console.log('OCR Results:', ocrResults); 
      matchTransactions(ocrResults);
    }
  }, [transactions, ocrResults]);


  const memoizedOcrResults = useMemo(() => ocrResults, [ocrResults]);

  useEffect(() => {
    if (transactions.length > 0 && memoizedOcrResults.length > 0 && transactions.some(t => t.status === 'unmatched')) {
      console.log('Matching transactions...'); 
      console.log('Transactions:', transactions); 
      console.log('OCR Results:', memoizedOcrResults); 
      matchTransactions(memoizedOcrResults);
    }
  }, [transactions, memoizedOcrResults]);

  useEffect(() => {
    console.log('Transactions updated:', transactions);
  }, [transactions]);

  const processBankFile = async () => {
    setLoading(true);
    setProcessingError(null);
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        Papa.parse(content, {
          header: true,
          complete: (result) => {
            const bankData = result.data;
            const formattedTransactions = bankData
              .filter(transaction => 
                transaction['Booking Date'] && 
                transaction['Amount'] && 
                !isNaN(parseFloat(transaction['Amount']))
              )
              .map((transaction, index) => ({
                id: index + 1,
                date: transaction['Booking Date'],
                amount: parseFloat(transaction['Amount']),
                partnerName: transaction['Partner Name'],
                status: 'unmatched'
              }));
            setTransactions(formattedTransactions);
            setShowUploadReceiptsPrompt(true);
          },
          error: (error) => {
            console.error('Error parsing CSV:', error);
            setProcessingError('Error processing bank file. Please try again.');
          }
        });
      };
      reader.readAsText(bankFile);
    } catch (error) {
      console.error('Error processing bank file:', error);
      setProcessingError('Error processing bank file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleBankFileUpload = (event) => {
    setBankFile(event.target.files[0]);
    setBankFileUploaded(true);
  };

  const handleReceiptsUpload = async (event) => {
    const files = [...event.target.files];
    setReceipts(files);
    setLoading(true);
    setOcrFeedback([]);
    setReceiptsUploaded(true);
  
    try {
      const results = await Promise.all(files.map(async (file) => {
        if (file.type === 'application/json') {
          const jsonResult = await file.text();
          let result;
          try {
            result = JSON.parse(jsonResult);
          } catch (error) {
            console.error('Error parsing JSON:', error);
            result = null;
          }
  
          if (Array.isArray(result)) {
            setOcrFeedback(prev => [...prev, { 
              filename: file.name, 
              success: result.length > 0,
              data: result
            }]);
            return result;
          } else if (result && typeof result === 'object') {
            setOcrFeedback(prev => [...prev, { 
              filename: file.name, 
              success: true,
              data: [result] // Wrap single object in an array
            }]);
            return [result]; // Wrap single object in an array
          } else {
            console.warn('Unexpected JSON format:', result);
            return null;
          }
        } else {
          const result = await processReceipt(file);
          setOcrFeedback(prev => [...prev, { 
            filename: file.name, 
            success: result !== null,
            data: result
          }]);
          return result;
        }
      }));
  
      // Flatten the array of results and filter out nulls
      const flattenedResults = results.flat().filter(result => result !== null);
      setOcrResults(flattenedResults);
      matchTransactions(flattenedResults);
    } catch (error) {
      console.error('Error uploading receipts:', error);
      setProcessingError('Error processing receipts. Please try again.');
    } finally {
      setLoading(false);
    }
  };  

  const processReceipt = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await axios.post('http://localhost:5001/upload-receipt', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('OCR Result for', file.name, ':', response.data);
      return response.data;
    } catch (error) {
      console.error('Error processing receipt:', error);
      return null;
    }
  };  

  const matchTransactions = (ocrResults) => {
    console.log('Starting transaction matching...');
    console.log('OCR Results:', JSON.stringify(ocrResults, null, 2));
    
    const updatedTransactions = transactions.map(transaction => {
      if (transaction.status !== 'unmatched') {
        return transaction; // Skip already matched or private transactions
      }
  
      const matchingReceipt = ocrResults.find(receipt => {
        const isAmountMatch = Math.abs(parseFloat(receipt.total) - transaction.amount) < 0.01;
        const isDateMatch = new Date(receipt.date).toLocaleDateString() === new Date(transaction.date).toLocaleDateString();
        
        return isAmountMatch && isDateMatch;
      });
  
      if (matchingReceipt) {
        console.log('Match Found:', matchingReceipt);
        return { 
          ...transaction, 
          status: 'matched', 
          receiptId: matchingReceipt.receipt_no 
        };
      } else {
        return transaction;
      }
    });
  
    // Only update state if there are actual changes
    if (JSON.stringify(updatedTransactions) !== JSON.stringify(transactions)) {
      console.log('Updated Transactions:', JSON.stringify(updatedTransactions, null, 2));
      setTransactions(updatedTransactions);
    } else {
      console.log('No changes in transactions after matching');
    }
  };


  // const matchTransactions = (ocrResults) => {
  //   console.log('Starting transaction matching...');
  //   console.log('OCR Results:', JSON.stringify(ocrResults, null, 2));
    
  //   const updatedTransactions = transactions.map(transaction => {
  //     if (transaction.status !== 'unmatched') {
  //       return transaction; // Skip already matched or private transactions
  //     }
  
  //     const matchingReceipt = ocrResults.find(receipt => {
  //       const isAmountMatch = Math.abs(parseFloat(receipt.total) - transaction.amount) < 0.01;
  //       const isDateMatch = Math.abs(new Date(receipt.date) - new Date(transaction.date)) <= 24 * 60 * 60 * 1000; // 1 day tolerance
  //       const isVendorMatch = receipt.vendor.toLowerCase().includes(transaction.partnerName.toLowerCase()) || 
  //                             transaction.partnerName.toLowerCase().includes(receipt.vendor.toLowerCase());
        
  //       return isAmountMatch && isDateMatch && isVendorMatch;
  //     });
  
  //     if (matchingReceipt) {
  //       console.log('Match Found:', matchingReceipt);
  //       return { 
  //         ...transaction, 
  //         status: 'matched', 
  //         receiptId: matchingReceipt.receipt_no 
  //       };
  //     } else {
  //       return transaction;
  //     }
  //   });
  
  //   // Check for unmatched receipts
  //   const unmatchedReceipts = ocrResults.filter(receipt => 
  //     !updatedTransactions.some(t => t.receiptId === receipt.receipt_no)
  //   );
  
  //   console.log('Unmatched Receipts:', unmatchedReceipts);
  
  //   // Only update state if there are actual changes
  //   if (JSON.stringify(updatedTransactions) !== JSON.stringify(transactions)) {
  //     console.log('Updated Transactions:', JSON.stringify(updatedTransactions, null, 2));
  //     setTransactions(updatedTransactions);
  //   } else {
  //     console.log('No changes in transactions after matching');
  //   }
  
  //   return { updatedTransactions, unmatchedReceipts };
  // };


  // const matchTransactions = (ocrResults) => {
  //   console.log('Starting transaction matching...');
  //   console.log('OCR Results:', JSON.stringify(ocrResults, null, 2));
    
  //   const updatedTransactions = transactions.map(transaction => {
  //     if (transaction.status !== 'unmatched') {
  //       return transaction; // Skip already matched or private transactions
  //     }
  
  //     // Group receipts by date and vendor
  //     const groupedReceipts = ocrResults.reduce((acc, receipt) => {
  //       const key = `${receipt.date}_${receipt.vendor}`;
  //       if (!acc[key]) acc[key] = [];
  //       acc[key].push(receipt);
  //       return acc;
  //     }, {});
  
  //     // Find matching receipts (single or grouped)
  //     const matchingReceipts = Object.values(groupedReceipts).find(receipts => {
  //       const totalAmount = receipts.reduce((sum, r) => sum + parseFloat(r.total), 0);
  //       const isAmountMatch = Math.abs(totalAmount - Math.abs(transaction.amount)) < 0.01;
  //       const isDateMatch = new Date(receipts[0].date).toLocaleDateString() === new Date(transaction.date).toLocaleDateString();
  //       const isVendorMatch = receipts[0].vendor.toLowerCase().includes(transaction.partnerName.toLowerCase()) ||
  //                             transaction.partnerName.toLowerCase().includes(receipts[0].vendor.toLowerCase());
        
  //       return isAmountMatch && isDateMatch && isVendorMatch;
  //     });
  
  //     if (matchingReceipts) {
  //       console.log('Match Found:', matchingReceipts);
  //       return { 
  //         ...transaction, 
  //         status: 'matched', 
  //         receiptIds: matchingReceipts.map(r => r.receipt_no)
  //       };
  //     } else {
  //       return transaction;
  //     }
  //   });
  
  //   // Only update state if there are actual changes
  //   if (JSON.stringify(updatedTransactions) !== JSON.stringify(transactions)) {
  //     console.log('Updated Transactions:', JSON.stringify(updatedTransactions, null, 2));
  //     setTransactions(updatedTransactions);
  //   } else {
  //     console.log('No changes in transactions after matching');
  //   }
  // };
  

  const handleStatusChange = (id, newStatus) => {
    setTransactions(prev => prev.map(t => t.id === id ? { ...t, status: newStatus } : t));
  };

  const handleModalOpen = (transaction) => {
    setSelectedTransaction(transaction);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedTransaction(null);
  };

  const handleSubmit = () => {
    if (transactions.every(t => t.status === 'matched' || t.status === 'private')) {
      setActiveStep(2);
    } else {
      setWarningOpen(true);
    }
  };

  const formatAmount = (amount) => {
    return `${amount.toFixed(2)} €`;
  };

  const handleEditModalOpen = (transaction) => {
    setSelectedTransaction(transaction);
    setCurrentComment(transaction.comment || '');
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setSelectedTransaction(null);
    setCurrentComment('');
  };

  const handleCommentSave = () => {
    setTransactions(prev => prev.map(t => 
      t.id === selectedTransaction.id ? { ...t, comment: currentComment } : t
    ));
    handleEditModalClose();
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Bankdatei hochladen (CAMT53-File, json oder csv)</Typography>
              <Input 
                type="file" 
                onChange={handleBankFileUpload} 
                accept=".csv" 
                disableUnderline
                endAdornment={<FiUpload />}
              />
              {bankFileUploaded && !showUploadReceiptsPrompt && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  Bankdatei erfolgreich hochgeladen. Bitte laden Sie die Belege hoch.
                </Alert>
              )}
              {showUploadReceiptsPrompt && (
                <Alert severity="info" sx={{ mt: 2 }}>
                  Die Bankdatei wurde erfolgreich verarbeitet.
                </Alert>
              )}
            </Paper>
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Belege hochladen (PDF, JPEG oder PNG)</Typography>
              <Input 
                type="file" 
                onChange={handleReceiptsUpload} 
                accept=".pdf,.png,.jpg,.jpeg,.json" 
                multiple 
                disableUnderline
                endAdornment={<FiUpload />}
                disabled={!bankFileUploaded || showUploadReceiptsPrompt}
              />
              {receiptsUploaded && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  Belege erfolgreich hochgeladen.
                </Alert>
              )}
            </Paper>
            {loading && <CircularProgress />}
            {ocrFeedback.length > 0 && (
              <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                <Typography variant="h6" gutterBottom>OCR Feedback</Typography>
                <List>
                  {ocrFeedback.map((feedback, index) => (
                    <ListItem key={index}>
                      <ListItemText 
                        primary={`Beleg: ${feedback.filename}`} 
                        secondary={feedback.success 
                          ? `OCR erfolgreich: Betrag ${feedback.data.total}, Datum ${feedback.data.date}`
                          : 'OCR fehlgeschlagen'}
                      />
                      {feedback.success 
                        ? <FiCheckCircle color="green" /> 
                        : <FiAlertCircle color="red" />
                      }
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
            {uploadSuccess && (
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Button 
                  onClick={() => setActiveStep(1)} 
                  variant="contained"
                  color="primary"
                >
                  Weiter zu Transaktionen prüfen
                </Button>
              </Box>
            )}
          </Box>
        );
      case 1:
        return (
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Datum</TableCell>
                  <TableCell>Partner Name</TableCell>
                  <TableCell>Betrag</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Kommentar</TableCell>
                  <TableCell>Aktionen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map(transaction => (
                  <TableRow key={transaction.id}>
                    <TableCell>{transaction.date}</TableCell>
                    <TableCell>{transaction.partnerName}</TableCell>
                    <TableCell>{formatAmount(transaction.amount)}</TableCell>
                    <TableCell>
                      <Chip 
                        icon={transaction.status === 'unmatched' ? <FiAlertCircle /> : <FiCheckCircle />}
                        label={
                          transaction.status === 'matched' ? 'Abgeglichen' : 
                          transaction.status === 'private' ? 'Privat' : 'Nicht abgeglichen'
                        }
                        color={transaction.status === 'unmatched' ? 'error' : 'success'}
                      />
                    </TableCell>
                    <TableCell>{transaction.comment || '-'}</TableCell>
                    <TableCell>
                      <Select 
                        value={transaction.status} 
                        onChange={(e) => handleStatusChange(transaction.id, e.target.value)}
                        variant="outlined"
                        sx={{ minWidth: '120px', mr: 1 }}
                      >
                        <MenuItem value="matched">Abgeglichen</MenuItem>
                        <MenuItem value="unmatched">Nicht abgeglichen</MenuItem>
                        <MenuItem value="private">Privat</MenuItem>
                      </Select>
                      <Tooltip title="Details anzeigen">
                        <IconButton onClick={() => handleModalOpen(transaction)}>
                          <FiInfo />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Kommentar bearbeiten">
                        <IconButton onClick={() => handleEditModalOpen(transaction)}>
                          <FiEdit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Button 
                onClick={handleSubmit} 
                variant="contained"
                color="primary"
                startIcon={<FiDollarSign />}
              >
                An den Steuerberater übermitteln
              </Button>
            </Box>
          </Box>
        );  
      case 2:
        return (
          <Box textAlign="center">
            <FiCheckCircle size={64} color={theme.palette.success.main} />
            <Typography variant="h5" mt={2}>
              Daten erfolgreich an den Steuerberater übermittelt!
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box p={4} sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
        <Typography variant="h4" gutterBottom color="primary">
          DOCSENSE TAX Technologie - Bankbereich
        </Typography>

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {renderStepContent(activeStep)}

        {processingError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {processingError}
          </Alert>
        )}

        <Modal
          open={editModalOpen}
          onClose={handleEditModalClose}
          aria-labelledby="edit-comment-title"
        >
          <Box sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            width: 400, 
            bgcolor: 'background.paper',
            boxShadow: 24, 
            p: 4,
            borderRadius: 2
          }}>
            <Typography id="edit-comment-title" variant="h6" component="h2" gutterBottom>
              Kommentar bearbeiten
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={currentComment}
              onChange={(e) => setCurrentComment(e.target.value)}
              placeholder="Geben Sie hier Ihren Kommentar ein..."
              variant="outlined"
              sx={{ mb: 2 }}
            />
            <Button onClick={handleCommentSave} variant="contained" color="primary" fullWidth>
              Kommentar speichern
            </Button>
          </Box>
        </Modal>

        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="transaction-details-title"
        >
          <Box sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            width: 400, 
            bgcolor: 'background.paper',
            boxShadow: 24, 
            p: 4,
            borderRadius: 2
          }}>
            <Typography id="transaction-details-title" variant="h6" component="h2" gutterBottom>
              Transaktionsdetails
            </Typography>
            {selectedTransaction && (
              <List>
                <ListItem>
                  <ListItemText primary="Datum" secondary={selectedTransaction.date} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Partner Name" secondary={selectedTransaction.partnerName} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Beschreibung" secondary={selectedTransaction.description} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Betrag" secondary={formatAmount(selectedTransaction.amount)} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Status" secondary={selectedTransaction.status} />
                </ListItem>
                {selectedTransaction.receiptId !== null && (
                  <ListItem>
                    <ListItemText 
                      primary="Zugeordneter Beleg" 
                      secondary={`Beleg ${selectedTransaction.receiptId + 1}`} 
                    />
                  </ListItem>
                )}
              </List>
            )}
            <Button onClick={handleModalClose} variant="contained" color="primary" fullWidth>
              Schließen
            </Button>
          </Box>
        </Modal>

        <Snackbar 
          open={warningOpen} 
          autoHideDuration={6000} 
          onClose={() => setWarningOpen(false)}
        >
          <Alert onClose={() => setWarningOpen(false)} severity="warning">
            Bitte ordnen Sie alle Transaktionen zu, bevor Sie fortfahren.
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default DOCSENSETaxBank;
