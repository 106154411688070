import React, { useState } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  Link
} from 'react-router-dom';
import {
  Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel,
  Paper, Grid, Container, CssBaseline, ThemeProvider, createTheme, AppBar, Toolbar
} from '@mui/material';
import DOCSENSETaxBank from './DOCSENSETaxBank';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const Layout = ({ children }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component={Link} to="/" style={{ textDecoration: 'none', color: 'white' }}>
            DOCSENSE TAX
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {isLoggedIn ? (
            <>
              <Typography variant="body1" sx={{ mr: 2 }}>
                Willkommen, {currentUser?.email}
              </Typography>
              <Button color="inherit" onClick={() => { setIsLoggedIn(false); setCurrentUser(null); }}>
                Abmelden
              </Button>
            </>
          ) : (
            <>
              <Button color="inherit" component={Link} to="/login">
                Anmelden
              </Button>
              <Button color="inherit" component={Link} to="/signup">
                Registrieren
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Container component="main" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
        {children}
      </Container>
      <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: (theme) => theme.palette.grey[200] }}>
        <Container maxWidth="sm">
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} DOCSENSE TAX. Alle Rechte vorbehalten.
          </Typography>
        </Container>
      </Box>
    </Box>
  );

  const LandingPage = () => {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: 'calc(100vh - 64px - 56px)', // Adjust for AppBar and footer
        backgroundColor: '#f5f5f5'
      }}>
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 400 }}>
          <Typography variant="h4" gutterBottom align="center">
            Willkommen bei DOCSENSE TAX
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            Ihre digitale Lösung für Steuererklärungen
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Button 
                component={Link} 
                to="/signup" 
                variant="contained" 
                color="primary" 
                fullWidth
              >
                Jetzt starten
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button 
                component={Link} 
                to="/login" 
                variant="outlined" 
                color="primary" 
                fullWidth
              >
                Anmelden
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  };

  const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
      e.preventDefault();
      // Here you would normally send an API request
      // For this example, we're simulating a successful login
      setIsLoggedIn(true);
      setCurrentUser({ email });
      navigate('/app');
    };

    return (
      <Box component="form" onSubmit={handleLogin} sx={{ maxWidth: 400, margin: 'auto', mt: 5 }}>
        <Typography variant="h5" gutterBottom>Anmelden</Typography>
        <TextField
          fullWidth
          margin="normal"
          label="E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Passwort"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
          Anmelden
        </Button>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link to="/signup" style={{ textDecoration: 'none' }}>Noch kein Konto? Registrieren Sie sich hier</Link>
        </Box>
      </Box>
    );
  };

  const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [consultant, setConsultant] = useState('');
    const navigate = useNavigate();

    const handleSignUp = (e) => {
      e.preventDefault();
      // Here you would normally send an API request
      // For this example, we're going directly to the login page
      alert('Registrierung erfolgreich! Bitte melden Sie sich an.');
      navigate('/login');
    };

    return (
      <Box component="form" onSubmit={handleSignUp} sx={{ maxWidth: 400, margin: 'auto', mt: 5 }}>
        <Typography variant="h5" gutterBottom>Registrieren</Typography>
        <TextField
          fullWidth
          margin="normal"
          label="E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          fullWidth
          margin="normal"
          label="Passwort"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Steuerberater</InputLabel>
          <Select
            value={consultant}
            onChange={(e) => setConsultant(e.target.value)}
            required
          >
            <MenuItem value="alpTax">AlpenTax Steuerberatung GmbH</MenuItem>
            <MenuItem value="donauConsult">DonauConsult Steuerexperten</MenuItem>
            <MenuItem value="salzachPartner">Salzach Steuerberatung & Partner</MenuItem>
            <MenuItem value="steuerprofiTirol">Steuerprofi Tirol GmbH</MenuItem>
            <MenuItem value="viennaTax">ViennaTax Solutions</MenuItem>
            <MenuItem value="osterreichSteuerhaus">Österreichische Steuerberatungshaus</MenuItem>
            <MenuItem value="bergblick">Bergblick Steuerexperten</MenuItem>
            <MenuItem value="alpenKanzlei">AlpenKanzlei Steuerberatung</MenuItem>
            <MenuItem value="innsbruckTax">Innsbruck Tax Consulting</MenuItem>
            <MenuItem value="danubeSteuerberater">Danube Steuerberater GmbH</MenuItem>    
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
          Registrieren
        </Button>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Link to="/login" style={{ textDecoration: 'none' }}>Bereits registriert? Melden Sie sich hier an</Link>
        </Box>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={isLoggedIn ? <Navigate to="/app" /> : <Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/app" element={isLoggedIn ? <DOCSENSETaxBank /> : <Navigate to="/login" />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;